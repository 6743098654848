body.show-menu #id-showed-sidebar {
  position: relative;
  z-index: 1500;
}

.cls-material-grid-view-ui colgroup[role="presentation"] {
  display: none;
}
.cls-material-grid-view-ui tbody[role="presentation"] {
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  row-gap: 14px;
  column-gap: 14px;
  box-sizing: border-box;
}
.cls-material-grid-view-ui tbody[role="presentation"] tr.k-master-row td {
  border-color: transparent !important;
}

.cls-material-grid-view-ui tbody[role="presentation"] tr.k-master-row {
  position: relative;
  background-color: white !important;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  box-shadow: 1px 1px 5px #0000007d;
  border: 4px solid white;
  &.k-state-selected {
    border: 4px solid var(--optionBgColor);
  }
  & > td {
    border: 4px solid white !important;
    background-color: white !important;
  }
}

.cls-material-grid-view-ui
  tbody[role="presentation"]
  tr.k-master-row
  .cls-action-button-col {
  position: absolute;
  right: 0 !important;
  top: 0 !important;
}

/* PRODUCT LOOKUP CHANGES START */
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[role="gridcell"] {
  display: none;
}
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="0"],
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="1"],
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="-2"] {
  display: block;
  text-align: right;
}
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="0"]::before {
  content: "Storage Id: ";
  font-weight: bold;
}
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="1"]::before {
  content: "Storage Name: ";
  font-weight: bold;
}
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="2"]::before {
  content: "Manager: ";
  font-weight: bold;
}
.cls-lookup-kits-products .cls-material-grid-view-ui thead {
  display: none !important;
}

.cls-lookup-kits-products
  .cls-material-grid-view-ui
  #id-td-switch-cell
  .cls-img-status-kit {
  display: block !important;
  width: 50px;
  height: 50px;
}
.cls-lookup-kits-products
  .cls-material-grid-view-ui
  #id-td-switch-cell
  .cls-status-kit {
  background-color: transparent;
  color: black;
}
.cls-lookup-kits-products .cls-material-grid-view-ui #id-td-switch-cell {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  border-right: 1px solid #ccc !important;
  height: 74px;
  margin: auto;
}

/* PRODUCT LOOKUP CHANGES END */

/* STORES LOOKUP CHANGES START */
.cls-lookup-kits-stores
  .cls-material-grid-view-ui
  tr.k-master-row
  td[role="gridcell"] {
  display: none;
}
.cls-lookup-kits-stores
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="0"],
.cls-lookup-kits-stores
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="1"] {
  display: block;
}
.cls-lookup-kits-stores
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="0"]::before {
  content: "Store Id: ";
  font-weight: bold;
}
.cls-lookup-kits-stores
  .cls-material-grid-view-ui
  tr.k-master-row
  td[data-grid-col-index="1"]::before {
  content: "Store Name: ";
  font-weight: bold;
}
.cls-lookup-kits-stores .cls-material-grid-view-ui thead {
  display: none !important;
}

/* STORES LOOKUP CHANGES END */

.cls-tenant-country {
  width: calc(100% - 20px);
}
